import React, { useEffect, useState, ChangeEvent } from "react";
import {
    Box,
    Textarea,
    Text,
    useColorModeValue,
    VStack,
    HStack,
    CircularProgress,
    CircularProgressLabel,
    Spacer,
    Stepper,
    Step,
    StepIndicator,
    StepStatus,
    StepIcon,
    StepNumber,
    StepTitle,
    StepSeparator,
} from "@chakra-ui/react";
import {CasperInteraction, CasperScenario} from "../../models/Models";
import { CasperAPI } from "../../APIs/CasperAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../ui/button/Button";
import {FiArrowDownCircle, FiArrowRight, FiArrowUpRight} from "react-icons/all";
import {SingleInteraction} from "./InteractionsHistory";

enum Phase {
    Confirmation,
    ScenarioReading,
    Questions,
    Finished,
}

const EvaluateCasperBoxCompetitive: React.FC = () => {
    const { scenario_id } = useParams();
    const navigate = useNavigate();

    const [scenario, setScenario] = useState<CasperScenario | null>(null);
    const [answers, setAnswers] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const scenarioReadingTime = 120;
    const questionTime = 300;

    const [phase, setPhase] = useState<Phase>(Phase.Confirmation);
    const [scenarioTimeLeft, setScenarioTimeLeft] = useState<number>(scenarioReadingTime);
    const [questionTimeLeft, setQuestionTimeLeft] = useState<number>(questionTime);
    const [editingDisabled, setEditingDisabled] = useState<boolean>(false);

    const [interaction, setInteraction] = useState<CasperInteraction | null>(null);

    const boxBg = useColorModeValue("white", "gray.700");
    const borderColor = useColorModeValue("gray.200", "gray.600");

    useEffect(() => {
        if (scenario_id) {
            getAndOpenScenario(scenario_id);
        }
    }, [scenario_id]);

    const getAndOpenScenario = async (scenario_id: string) => {
        try {
            const scenario = await CasperAPI.get_casper_scenario(scenario_id);
            setScenario(scenario);
            setAnswers(Array(scenario.questions.length).fill(""));
        } catch {
            setError("Failed to load scenario");
        }
    };

    useEffect(() => {
        if (phase === Phase.ScenarioReading) {
            if (scenarioTimeLeft > 0) {
                const timer = setTimeout(() => setScenarioTimeLeft(scenarioTimeLeft - 1), 1000);
                return () => clearTimeout(timer);
            } else {
                setPhase(Phase.Questions);
            }
        }
    }, [phase, scenarioTimeLeft]);

    useEffect(() => {
        if (phase === Phase.Questions) {
            if (questionTimeLeft > 0) {
                const timer = setTimeout(() => setQuestionTimeLeft(questionTimeLeft - 1), 1000);
                return () => clearTimeout(timer);
            } else {
                setEditingDisabled(true);
            }
        }
    }, [phase, questionTimeLeft]);

    const handleEvaluateAnswer = async () => {
        setLoading(true);
        setError(null);
        try {
            if (scenario) {
                const res = await CasperAPI.evaluate_casper_scenario(
                    scenario,
                    answers,
                    questionTime - questionTimeLeft
                );
                if (res) {
                    setInteraction(res)
                    setPhase(Phase.Finished);
                }
            }
        } catch (err) {
            console.error("Evaluation error:", err);
            setError("An error occurred during evaluation.");
        }
        setLoading(false);
    };

    const [isExpanded, setIsExpanded] = useState(true);

    return (
        <>
            {phase !== Phase.Confirmation && scenario && (
                <HStack
                    w={{ base: "90%", md: "80%", lg: "70%" }}
                    maxW="4xl"
                    mx="auto"
                    mt={8}
                >
                    <Spacer/>
                    <Button
                        colorScheme="red"
                        withLeftArrow
                        onClick={()=>{window.location.reload()}}
                    >
                        Start Over
                    </Button>
                </HStack>
            )}
            <Box
                p={6}
                bg={boxBg}
                borderRadius="md"
                w={{ base: "90%", md: "80%", lg: "70%" }}
                maxW="4xl"
                mx="auto"
                mt={8}
                shadow="md"
                borderWidth="1px"
                borderColor={borderColor}
            >
                {phase === Phase.Confirmation && scenario && (
                    <ConfirmationDialog onConfirm={() => setPhase(Phase.ScenarioReading)} />
                )}
                {phase === Phase.ScenarioReading && scenario && (
                    <ScenarioComponent
                        scenario={scenario}
                        timeLeft={scenarioTimeLeft}
                        onSkip={() => {
                            setPhase(Phase.Questions);
                            setScenarioTimeLeft(0);
                        }}
                    />
                )}
                {phase === Phase.Questions && scenario && (
                    <QuestionsComponent
                        scenario={scenario}
                        answers={answers}
                        setAnswers={setAnswers}
                        timeLeft={questionTimeLeft}
                        onSubmit={handleEvaluateAnswer}
                        editingDisabled={editingDisabled}
                        loading={loading}
                        error={error}
                    />
                )}
                {phase === Phase.Finished && (
                    <Box textAlign="left" py={10} px={6}>
                        <Text fontSize="xl" mb={4}>
                            Thank you! Your answers have been submitted.
                        </Text>

                        <SingleInteraction
                            scenario={scenario!}
                            interaction={interaction!}
                            isExpanded={isExpanded}
                            onToggle={() => setIsExpanded((prev) => !prev)} // Toggle expand state
                        />
                        <Spacer height={"15px"}/>
                        <Button variant={"primary"} onClick={() => navigate("/history")} withRightArrow>
                            Go to History
                        </Button>
                    </Box>
                )}
                {!scenario && <Text>Loading scenario...</Text>}
            </Box>
        </>
    );
};

type ConfirmationDialogProps = {
    onConfirm: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({ onConfirm }) => {
    return (
        <Box textAlign="center" py={10} px={6}>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
                Ready to Begin?
            </Text>
            <Text fontSize="md" mb={6}>
                You are about to start the evaluation. Here's what to expect:
            </Text>
            <VStack spacing={4} align="start" mb={6}>
                <HStack>
                    <Box w={4} h={4} bg="blue.500" borderRadius="full" />
                    <Text>
                        <strong>2 minutes</strong> to read the provided scenario.
                    </Text>
                </HStack>
                <HStack>
                    <Box w={4} h={4} bg="green.500" borderRadius="full" />
                    <Text>
                        <strong>5 minutes</strong> to answer the 3 questions.
                    </Text>
                </HStack>
                <HStack>
                    <Box w={4} h={4} bg="yellow.500" borderRadius="full" />
                    <Text>
                        You can skip the reading phase if you're ready to proceed directly to the questions.
                    </Text>
                </HStack>
            </VStack>
            <Button variant={"primary"} onClick={onConfirm} withRightArrow>
                Start Evaluation
            </Button>
        </Box>
    );
};

type ScenarioComponentProps = {
    scenario: CasperScenario;
    timeLeft: number;
    onSkip: () => void;
};

const ScenarioComponent: React.FC<ScenarioComponentProps> = ({ scenario, timeLeft, onSkip }) => {
    const responseBg = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.200", "gray.600");
    const scenarioReadingTime = 120;

    const formatTime = (seconds: number) =>
        `${String(Math.floor(seconds / 60)).padStart(2, "0")}:${String(seconds % 60).padStart(2, "0")}`;

    return (
        <VStack spacing={6} align="stretch">
            <HStack>
                <CircularProgress
                    value={(timeLeft / scenarioReadingTime) * 100}
                    size="64px"
                    thickness="6px"
                    color="blue.500"
                >
                    <CircularProgressLabel fontSize="sm">{formatTime(timeLeft)}</CircularProgressLabel>
                </CircularProgress>
            </HStack>
            <Box p={4} bg={responseBg} borderRadius="md" borderWidth="1px" borderColor={borderColor}>
                <Text fontSize="2xl" fontWeight="bold" mb={2}>
                    Scenario
                </Text>
                <Text fontSize="md">{scenario.scenario}</Text>
            </Box>
            <HStack>
                <Button onClick={onSkip} variant="primary" withRightArrow>
                    Proceed to Questions
                </Button>
                <Spacer/>
            </HStack>
        </VStack>
    );
};

type QuestionsComponentProps = {
    scenario: CasperScenario;
    answers: string[];
    setAnswers: React.Dispatch<React.SetStateAction<string[]>>;
    timeLeft: number;
    onSubmit: () => void;
    editingDisabled: boolean;
    loading: boolean;
    error: string | null;
};

const QuestionsComponent: React.FC<QuestionsComponentProps> = ({
                                                                   scenario,
                                                                   answers,
                                                                   setAnswers,
                                                                   timeLeft,
                                                                   onSubmit,
                                                                   editingDisabled,
                                                                   loading,
                                                                   error,
                                                               }) => {
    const questionTime = 300;

    const formatTime = (seconds: number) =>
        `${String(Math.floor(seconds / 60)).padStart(2, "0")}:${String(
            seconds % 60
        ).padStart(2, "0")}`;

    const handleAnswerChange =
        (index: number) => (event: ChangeEvent<HTMLTextAreaElement>) => {
            const newAnswers = [...answers];
            newAnswers[index] = event.target.value;
            setAnswers(newAnswers);
        };

    return (
        <VStack spacing={6} align="stretch">
            <HStack>
                <CircularProgress
                    value={(timeLeft / questionTime) * 100}
                    size="64px"
                    thickness="6px"
                    color="red.500"
                >
                    <CircularProgressLabel fontSize="sm">
                        {formatTime(timeLeft)}
                    </CircularProgressLabel>
                </CircularProgress>
                <Spacer />
            </HStack>
            {scenario.questions.map((questionText, index) => (
                <Box key={index}>
                    <Text fontSize="xl" fontWeight="bold" mb={2}>
                        {`Question ${index + 1}`}
                    </Text>
                    <Text fontSize="md" mb={4}>
                        {questionText}
                    </Text>
                    <Textarea
                        value={answers[index] || ""}
                        onChange={handleAnswerChange(index)}
                        placeholder={`Type your answer to Question ${index + 1} here`}
                        variant="filled"
                        size="lg"
                        resize="vertical"
                        minH="120px"
                        isDisabled={editingDisabled}
                    />
                    <Text
                        alignSelf="flex-end"
                        fontSize="sm"
                        color="gray.500"
                        mt={1}
                        textAlign="right"
                    >
                        {answers[index]?.length ?? 0}/3000 characters
                    </Text>
                </Box>
            ))}
            <Box display="flex" justifyContent="flex-end" mt={6}>
                <Button
                    onClick={onSubmit}
                    variant={"primary"}
                    withRightArrow
                    size="lg"
                    isLoading={loading}
                    isDisabled={loading}
                >
                    Submit
                </Button>
            </Box>
            {error && (
                <Text color="red.500" mt={2}>
                    {error}
                </Text>
            )}
        </VStack>
    );
};

export default EvaluateCasperBoxCompetitive;