import React, { useRef, useState } from "react";
import Webcam from "react-webcam";
import {
    Box,
    Heading,
    VStack,
    Flex,
    useColorModeValue,
    Skeleton
} from "@chakra-ui/react";
import Button from "../../ui/button/Button";

const VideoRecorder: React.FC = () => {
    const webcamRef = useRef<Webcam>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const [showWebcam, setShowWebcam] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState<Blob[]>([]);
    const [isRecording, setIsRecording] = useState(false);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);

    // Track loading state after stop-record is triggered,
    // until the video URL is set.
    const [isVideoLoading, setIsVideoLoading] = useState(false);

    const handleStartRecording = () => {
        // Show the webcam preview
        setShowWebcam(true);

        // Small delay to ensure the webcam stream has started
        setTimeout(() => {
            if (webcamRef.current && webcamRef.current.stream) {
                setIsRecording(true);
                mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
                    mimeType: "video/webm",
                });

                mediaRecorderRef.current.ondataavailable = (event: BlobEvent) => {
                    if (event.data.size > 0) {
                        setRecordedChunks((prev) => prev.concat(event.data));
                    }
                };

                mediaRecorderRef.current.start();
            }
        }, 200);
    };

    const handleStopRecording = () => {
        setIsRecording(false);

        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }

        // Once we stop recording, hide the webcam preview
        setShowWebcam(false);
        // Start loading while video preview is being generated
        setIsVideoLoading(true);

        // After onstop event fires, the final data is pushed to recordedChunks.
        // Use a small delay to ensure recordedChunks is updated.
        setTimeout(() => {
            if (recordedChunks.length) {
                const blob = new Blob(recordedChunks, { type: "video/webm" });
                const url = URL.createObjectURL(blob);
                setVideoUrl(url);
            }
            setIsVideoLoading(false);
        }, 400);
    };

    const handleSave = async () => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, { type: "video/webm" });
            const file = new File([blob], "video.webm", { type: "video/webm" });
            uploadToS3(file);
        }
    };

    const uploadToS3 = async (file: File) => {
        const formData = new FormData();
        formData.append("file", file);

        // Example skeleton code:
        // try {
        //   const response = await fetch("/YOUR_BACKEND_ENDPOINT", {
        //     method: "POST",
        //     body: formData,
        //   });
        //   const data = await response.json();
        //   console.log("File uploaded successfully:", data);
        // } catch (error) {
        //   console.error("Error uploading file:", error);
        // }
    };

    // (Optional) Color mode styling for the container
    const bgColor = useColorModeValue("white", "gray.800");

    return (
        <Flex
            minH="100vh"
            align="center"
            width="100%"
            justify="center"
            bg={useColorModeValue("gray.100", "gray.900")}
            p={4}
        >
            <Box
                bg={bgColor}
                p={6}
                borderRadius="lg"
                boxShadow="lg"
                maxW="xl"
                width="100%"
                textAlign="center"
            >
                <Heading as="h1" size="lg" mb={6}>
                    Video Recorder
                </Heading>

                {/* Display a small label indicating recording status */}
                {isRecording && (
                    <Box mb={4} color="red.500" fontWeight="bold">
                        Recording in progress...
                    </Box>
                )}

                {/* Conditionally render webcam preview */}
                {showWebcam && (
                    <Box mb={6} overflow="hidden" borderRadius="md" w="80%" mx="auto">
                        <Webcam
                            audio
                            muted={true} // Prevent hearing your own voice
                            ref={webcamRef}
                            style={{ width: "100%", borderRadius: "0.5rem" }}
                        />
                    </Box>
                )}

                {/* Buttons */}
                <VStack spacing={4}>
                    <Button
                        onClick={handleStartRecording}
                        width="full"
                        variant="primary"
                        withRightArrow
                        isDisabled={isRecording}
                    >
                        Start Recording
                    </Button>

                    <Button
                        onClick={handleStopRecording}
                        width="full"
                        variant="primary"
                        withLeftArrow
                        isDisabled={!isRecording}
                    >
                        Stop Recording
                    </Button>

                    <Button
                        onClick={handleSave}
                        width="full"
                        variant="primary"
                        withRightArrow
                        isDisabled={recordedChunks.length === 0}
                    >
                        Save & Upload
                    </Button>
                </VStack>

                {/* Display recorded video preview after stop */}
                {/* If we're in the process of generating videoUrl, show a Skeleton. */}
                {isVideoLoading ? (
                    <Box mt={6}>
                        <Heading size="md" mb={3}>
                            Loading Video Preview...
                        </Heading>
                        <Skeleton height="300px" borderRadius="0.5rem" />
                    </Box>
                ) : (
                    videoUrl && (
                        <Box mt={6}>
                            <Heading size="md" mb={3}>
                                Recorded Video Preview
                            </Heading>
                            <video
                                controls
                                style={{ width: "100%", borderRadius: "0.5rem" }}
                            >
                                <source src={videoUrl} type="video/webm" />
                                Your browser does not support the video tag.
                            </video>
                        </Box>
                    )
                )}
            </Box>
        </Flex>
    );
};

export default VideoRecorder;