import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import {
    Badge,
    Box,
    Divider,
    Flex,
    Heading,
    ListItem,
    OrderedList,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { GraphData, ListData } from '../models/Statistics';

export const LineGraph: React.FC<{ id: string; graphData: GraphData }> = ({ id, graphData }) => {
    const chartRef = useRef<Chart | null>(null);
    const lineColor = useColorModeValue('rgba(72, 170, 188, 1)', 'rgba(115, 53, 241, 1)');
    const gradientColor = useColorModeValue('rgba(72, 170, 188, ', 'rgba(115, 53, 241, ');

    const createGradient = (ctx: CanvasRenderingContext2D) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 400);
        gradient.addColorStop(0, `${gradientColor}0.5)`);
        gradient.addColorStop(0.35, `${gradientColor}0.25)`);
        gradient.addColorStop(1, `${gradientColor}0)`);
        return gradient;
    };

    useEffect(() => {
        const canvas = document.getElementById(id) as HTMLCanvasElement | null;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        // Prepare labels and data arrays from the new GraphData structure
        const labels = graphData.items.map(item => item.label);
        const values = graphData.items.map(item => item.value);

        const gradient = createGradient(ctx);

        // Destroy existing chart instance if it exists
        if (chartRef.current) {
            chartRef.current.destroy();
        }

        // Create new chart instance
        chartRef.current = new Chart(ctx, {
            type: 'line',
            data: {
                labels,
                datasets: [
                    {
                        label: graphData.title,
                        data: values,
                        fill: true,
                        backgroundColor: gradient,
                        pointBackgroundColor: lineColor,
                        borderColor: lineColor,
                        tension: 0.4,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        beginAtZero: true,
                        grid: {
                            color: 'rgba(80, 102, 120, 0.25)',
                        },
                        suggestedMax: Math.max(...values) * 1.1, // 10% space above top value
                    },
                    x: {
                        grid: {
                            display: false,
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        });

        // Cleanup on component unmount
        return () => {
            if (chartRef.current) {
                chartRef.current.destroy();
            }
        };
    }, [id, lineColor, gradientColor, graphData]);

    return (
        <Box
            width="100%"
            height={['300px', '350px', '400px']}
            position="relative"
            className="chart-container"
            bg={useColorModeValue('white', 'gray.800')}
            borderRadius="lg"
            boxShadow="md"
            p={4}
        >
            <Heading as="h3" size="md" mb={4}>
                {graphData.title}
            </Heading>
            <Box height="calc(100% - 32px)" width="100%">
                <canvas id={id} />
            </Box>
        </Box>
    );
};

export const ListItems: React.FC<{ listData: ListData }> = ({ listData }) => {
    const getBadgeColor = (trend?: 'up' | 'down') => {
        if (trend === 'up') return 'green';
        if (trend === 'down') return 'red';
        return 'yellow'; // Neutral option
    };

    const getTrendIcon = (trend?: 'up' | 'down') => {
        if (trend === 'up') return '▲';
        if (trend === 'down') return '▼';
        return '●'; // Neutral icon
    };

    return (
        <Box
            bg={useColorModeValue('white', 'gray.800')}
            borderRadius="lg"
            boxShadow="md"
            p={4}
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Heading as="h3" size="md" mb={4}>
                {listData.title}
            </Heading>
            <OrderedList spacing={4} ml={2} flex="1">
                {listData.items.map((item, i) => (
                    <React.Fragment key={i}>
                        <ListItem
                            fontSize="md"
                            pl={2}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Text fontWeight="medium">{item.label}</Text>
                            <Flex align="center">
                                <Text fontWeight="bold" fontSize="sm" mr={2}>
                                    {item.value}
                                </Text>
                                {item.trend && (
                                    <Badge colorScheme={getBadgeColor(item.trend)} ml={2}>
                                        {getTrendIcon(item.trend)}
                                    </Badge>
                                )}
                            </Flex>
                        </ListItem>
                        {i < listData.items.length - 1 && <Divider mt={3} />}
                    </React.Fragment>
                ))}
            </OrderedList>
        </Box>
    );
};