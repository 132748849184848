    import React, {useEffect, useRef, useState} from 'react';
    import {
    Box,
    Grid,
    Text,
    OrderedList,
    ListItem,
    Divider,
    Badge,
    Flex,
    Heading,
    useColorModeValue,
    GridItem, Center, Spacer
    } from '@chakra-ui/react';
    import { Chart, registerables } from 'chart.js';
    import {useAuth} from "../utils/firebase/authContext";
    import {formatDistanceToNow, isPast} from "date-fns";
    import {CasperAPI} from "../APIs/CasperAPI";
    import {LineGraph, ListItems} from "../components/Statistics";
    import {CasperDashboardData} from "../models/Statistics";

    Chart.register(...registerables);

    
    const Dashboard: React.FC = () => {
        const user = useAuth().user;
        const [time_left, set_time_left] = useState<string | undefined>(undefined);
        const [dashboard_data, set_dashboard_data] = useState<CasperDashboardData | undefined>(undefined);

        const getDashboard = async () => {
            try {
                const dashboard = await CasperAPI.get_casper_dashboard();
                set_dashboard_data(dashboard);
            } catch (error) {
            }
        };

        useEffect(() => {
            getDashboard().then();
        }, []);

        useEffect(() => {
            if (!user?.exam_date || isPast(new Date(user.exam_date))) {
                set_time_left(undefined);
                return;
            }

            const examDate = new Date(user.exam_date);
            const distance = formatDistanceToNow(examDate, { addSuffix: false });

            set_time_left(`${distance} left until Casper exam!`);
        }, [user]);

        return (
            <Box p={[4, 6, 8]}>
                <Heading as="h1" size="xl" mb={6}>Welcome {user?.full_name} </Heading>
                <Heading as="h1" size="xl" textAlign="center" mb={2}>Dashboard Overview</Heading>
                {
                    time_left ?
                        <Center>
                            <Badge  textAlign="center" size={"md"}>
                                {time_left}
                            </Badge>
                        </Center>
                        :
                        <>
                        </>
                }
                <Spacer h={"25px"}/>
                <Grid
                    templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
                    gap={6}
                >
                    {
                        dashboard_data ?
                            <>
                                <GridItem colSpan={[1, 1, 1]}>
                                    <LineGraph id="chart1" graphData={dashboard_data.questionsCompleted}/>
                                </GridItem>
                                <GridItem colSpan={[1, 1, 1]}>
                                    <LineGraph id="chart2" graphData={dashboard_data.averageQuartileScore} />
                                </GridItem>
                                <GridItem colSpan={[1, 2, 1]}>
                                    <LineGraph id="chart3" graphData={dashboard_data.wordsPerMinute} />
                                </GridItem>
                            </>
                            :
                            <>
                            </>
                    }
                </Grid>
                <Box height={['5px', '55px', '105px']}  />
                <Grid
                    templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
                    gap={6}
                >
                    {
                        dashboard_data ?
                            <>
                                <GridItem colSpan={[1, 1, 1]}>
                                    <ListItems listData={dashboard_data.topMissedCharacteristics}/>
                                </GridItem>
                                <GridItem colSpan={[1, 1, 1]}>
                                    <ListItems listData={dashboard_data.topHitOnCharacteristics} />
                                </GridItem>
                                <GridItem colSpan={[1, 2, 1]}>
                                    <ListItems listData={dashboard_data.lastAnsweredQuestions} />
                                </GridItem>
                            </>
                            :
                            <>
                            </>
                    }
                </Grid>
            </Box>
        );
    };

    export default Dashboard;