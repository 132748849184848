import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Text,
    VStack,
    useColorModeValue,
    CircularProgress,
    CircularProgressLabel,
    Collapse,
    Spinner,
} from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import { CasperInteraction, CasperScenario } from "../../models/Models";
import { CasperAPI } from "../../APIs/CasperAPI";
import CasperInteractionComp from "../../components/CasperInteractionComp";

interface InteractionHistoryItem {
    interaction: CasperInteraction;
    scenario: CasperScenario;
    isExpanded: boolean;
}

/**
 * SingleInteraction Component
 * Takes a scenario and interaction (plus expand logic) and renders its details.
 */
export const SingleInteraction: React.FC<{
    scenario: CasperScenario;
    interaction: CasperInteraction;
    isExpanded: boolean;
    onToggle: () => void;
}> = ({ scenario, interaction, isExpanded, onToggle }) => {
    const responseBg = useColorModeValue("primary.50", "primary.800");
    const borderColor = useColorModeValue("primary.200", "primary.700");

    return (
        <Box
            p={4}
            bg={responseBg}
            borderRadius="md"
            borderWidth="1px"
            borderColor={borderColor}
        >
            {/* Title & Toggle */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Text fontSize="lg" fontWeight="bold">
                    {scenario.scenario || `Scenario ${scenario._id}`}
                </Text>
                <Button size="md" minW="120px" onClick={onToggle}>
                    {isExpanded ? "Hide Details" : "View Details"}
                </Button>
            </Box>

            {/* Date */}
            <Text fontSize="sm" color="gray.500" mt={2}>
                {formatDistanceToNow(new Date(interaction.created_at), { addSuffix: true })}
            </Text>

            {/* Scores */}
            <Box mt={4} display="flex" flexWrap="wrap" gap={4}>
                {interaction.interaction_details.map((detail, idx) => (
                    <Box
                        key={idx}
                        textAlign="center"
                        width={{ base: "100%", sm: "48%", md: "23%" }}
                    >
                        <Text fontWeight="semibold" mb={2}>
                            Question {idx + 1}
                        </Text>
                        <CircularProgress
                            value={detail.score}
                            max={4}
                            size="60px"
                            color="green.400"
                            thickness="8px"
                        >
                            <CircularProgressLabel fontSize="md" fontWeight="bold">
                                {detail.score}/4
                            </CircularProgressLabel>
                        </CircularProgress>
                    </Box>
                ))}

                {interaction.time_elapsed && (
                    <Box
                        textAlign="center"
                        width={{ base: "100%", sm: "48%", md: "23%" }}
                    >
                        <Text fontWeight="semibold" mb={2}>
                            Seconds Elapsed
                        </Text>
                        <CircularProgress
                            value={interaction.time_elapsed}
                            max={300}
                            size="60px"
                            color="green.400"
                            thickness="8px"
                        >
                            <CircularProgressLabel fontSize="md" fontWeight="bold">
                                {interaction.time_elapsed} S
                            </CircularProgressLabel>
                        </CircularProgress>
                    </Box>
                )}
            </Box>

            {/* Expandable Details */}
            <Collapse in={isExpanded} animateOpacity>
                <Box mt={4}>
                    <CasperInteractionComp
                        interaction={interaction}
                        scenario={scenario}
                    />
                </Box>
            </Collapse>
        </Box>
    );
};

/**
 * (Optional) Wrapper component for your InteractionHistory.
 * Currently empty but you may fill it if you want a higher-level structure.
 */
export const InteractionHistory: React.FC = () => {
    return (
        <>
            {/*
                You could place <InteractionHistoryList /> here
                or other layout elements as needed.
            */}
        </>
    );
};

/**
 * Main InteractionHistoryList component: fetches data and displays a list of SingleInteraction
 */
const InteractionHistoryList: React.FC = () => {
    const [interactions, setInteractions] = useState<InteractionHistoryItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const boxBg = useColorModeValue("background", "background");
    const borderColor = useColorModeValue("primary.200", "primary.700");

    useEffect(() => {
        const fetchInteractions = async () => {
            setLoading(true);
            setError(null);
            try {
                // Fetch interactions
                const interactionsList: CasperInteraction[] =
                    await CasperAPI.get_self_interactions();

                // For each interaction, fetch its scenario
                const interactionsWithScenarios = await Promise.all(
                    interactionsList.map(async (interaction) => {
                        const scenario: CasperScenario =
                            await CasperAPI.get_casper_scenario(interaction.casper_scenario.toHexString());
                        return {
                            interaction,
                            scenario,
                            isExpanded: false,
                        };
                    })
                );

                setInteractions(interactionsWithScenarios);
            } catch (err) {
                console.error("Error fetching interactions:", err);
                setError("An error occurred while fetching interactions.");
            }
            setLoading(false);
        };

        fetchInteractions();
    }, []);

    const toggleExpand = (index: number) => {
        setInteractions((prevInteractions) =>
            prevInteractions.map((item, idx) => ({
                ...item,
                isExpanded: idx === index ? !item.isExpanded : false,
            }))
        );
    };

    return (
        <Box
            p={6}
            bg={boxBg}
            borderRadius="md"
            w={{ base: "90%", md: "80%", lg: "70%" }}
            maxW="4xl"
            mx="auto"
            mt={8}
            shadow="md"
            borderWidth="1px"
            borderColor={borderColor}
        >
            <VStack spacing={6} align="stretch">
                <Text fontSize="2xl" fontWeight="bold" mb={4}>
                    Interaction History
                </Text>

                {loading ? (
                    <Spinner size="xl" alignSelf="center" />
                ) : error ? (
                    <Text color="red.500">{error}</Text>
                ) : interactions.length === 0 ? (
                    <Text>No interactions found.</Text>
                ) : (
                    interactions.map((item, index) => (
                        <SingleInteraction
                            key={index}
                            scenario={item.scenario}
                            interaction={item.interaction}
                            isExpanded={item.isExpanded}
                            onToggle={() => toggleExpand(index)}
                        />
                    ))
                )}
            </VStack>
        </Box>
    );
};

export default InteractionHistoryList;