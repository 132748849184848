import * as React from 'react';
import { ChakraProvider, ColorModeScript, Spinner, Flex } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { AuthProvider, useAuth } from './utils/firebase/authContext';
import PageNotFound from './screens/PageNotFound';
import StatusPage from "./screens/StatusPage";
import { ComingSoon } from "./screens/ComingSoon";
import theme from "./theme";
import PrivacyPolicy from "./screens/Legal/PrivacyPolicy";
import LegalPolicy from "./screens/Legal/LegalPolicy";
import ContactUsPage from "./screens/ContuctUsPage";
import LandingNavigation from "./LandingStaticSite/LandingNavigation";
import {LandingPage} from "./LandingStaticSite/LandingPage";
import {PricingPage} from "./screens/Payments/PricingPage";

// const PrivateRoute = ({ children }: { children: JSX.Element }) => {
//     const { user, loading } = useAuth();
//
//     if (loading) {
//         return (
//             <Flex justify="center" align="center" height="100vh" bg="background">
//                 <Spinner size="xl" color="primary" />
//             </Flex>
//         );
//     }
//
//     return user ? children : <Navigate to="/login" />;
// };
//
// const AdminRoute = ({ children }: { children: JSX.Element }) => {
//     const { user, loading } = useAuth();
//
//     if (loading) {
//         return (
//             <Flex justify="center" align="center" height="100vh" bg="background">
//                 <Spinner size="xl" color="primary" />
//             </Flex>
//         );
//     }
//
//     // Check if user exists and is an admin
//     if (user && user.is_admin()) {
//         return children;
//     } else {
//         return <Navigate to="/login" />;
//     }
// };
//
// // Redirect to coming-soon page if user is not authenticated
// const RedirectIfLoggedIn = ({ children }: { children: JSX.Element }) => {
//     const { user, loading } = useAuth();
//
//     if (loading) {
//         return (
//             <Flex justify="center" align="center" height="100vh" bg="background">
//                 <Spinner size="xl" color="primary" />
//             </Flex>
//         );
//     }
//
//     return user ? <Navigate to="/" /> : children;
// };
//

export const LandingApp = () => (
    <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <AuthProvider>
            <Router>
                <Routes>
                    <Route path="/" element={<LandingNavigation />}>
                        {/* Publicly accessible routes */}
                        <Route index element={<LandingPage/>} />
                        <Route path="_coming-soon" element={<ComingSoon />} />
                        <Route path="status" element={<StatusPage />} />
                        {/*<Route path="pricing" element={<PricingPage/>} />*/}

                        {/* Legal */}
                        <Route path="privacy" element={<PrivacyPolicy />} />
                        <Route path="legal" element={<LegalPolicy />} />
                        <Route path="contact-us" element={<ContactUsPage />} />

                        {/* Fallback for unknown routes */}
                        <Route path="*" element={<PageNotFound />} />
                    </Route>
                </Routes>
            </Router>
        </AuthProvider>
    </ChakraProvider>
);

export default LandingApp;