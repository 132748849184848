import FLASK_HTTPS from "./_FLASK_API";
import { ErrorHandler } from "../utils/error";

export namespace StaffAPI {
    let route_name = "/staff";

    export const get_admin_dashboard = async (): Promise<any> => {
        try {
            const res = await FLASK_HTTPS.get(`${route_name}/get_admin_dashboard`);
            return res.data as any;
        } catch (error) {
            ErrorHandler.handleAPIError(error, 'Error fetching admin dashboard data');
            // Throw an error to ensure the calling code handles it
            throw new Error('Failed to fetch admin dashboard data');
        }
    };
}