// Enum for EmailSubscription status
import {ObjectId} from "bson";

export enum EmailSubscriptionStatusEnum {
    Subscribed = 'SUBSCRIBED',
    Unsubscribed = 'UNSUBSCRIBED',
}

// EmailSubscription class
export class EmailSubscription {
    _id: ObjectId;
    email: string;
    status: EmailSubscriptionStatusEnum;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {
        this._id = new ObjectId(data._id.$oid);
        this.email = data.email;
        this.status = data.status || EmailSubscriptionStatusEnum.Subscribed;
        this.created_at = new Date(data.created_at.$date);
        this.updated_at = new Date(data.updated_at.$date);
    }
}

// Enum for FormSubmission status
export enum FormSubmissionStatusEnum {
    Pending = 'PENDING',
    Reviewed = 'REVIEWED',
    Resolved = 'RESOLVED',
}

// FormSubmission class
export class FormSubmission {
    _id: ObjectId;
    name: string;
    email: string;
    message: string;
    status: FormSubmissionStatusEnum;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {
        this._id = new ObjectId(data._id.$oid);
        this.name = data.name;
        this.email = data.email;
        this.message = data.message;
        this.status = data.status || FormSubmissionStatusEnum.Pending;
        this.created_at = new Date(data.created_at.$date);
        this.updated_at = new Date(data.updated_at.$date);
    }
}

// Enum for Feedback categories
export enum FeedbackCategoryEnum {
    BUG_REPORT = "BUG_REPORT",
    FEATURE_REQUEST = "FEATURE_REQUEST",
    GENERAL_FEEDBACK = "GENERAL_FEEDBACK",
    CASPREP_PRACTICE_SCENARIO_FEEDBACK = "CASPREP_PRACTICE_SCENARIO_FEEDBACK",
    CASPREP_COMPETITIVE_SCENARIO_FEEDBACK = "CASPREP_COMPETITIVE_SCENARIO_FEEDBACK",
    OTHER = "OTHER",
}

export class Feedback {
    _id: ObjectId;
    user?: ObjectId;
    user_metadata: Record<string, any>;
    text: string;
    rating: number;
    target_id?: ObjectId;
    category: FeedbackCategoryEnum;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {
        this._id = new ObjectId(data._id.$oid);
        this.user = data.user ? new ObjectId(data.user.$oid) : undefined;
        this.user_metadata = data.user_metadata || {};
        this.text = data.text;
        this.rating = data.rating;
        this.target_id = data.target_id ? new ObjectId(data.target_id.$oid) : undefined;
        this.category = data.category;
        this.created_at = new Date(data.created_at.$date);
        this.updated_at = new Date(data.updated_at.$date);
    }
}