import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    GridItem,
    Heading,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { Chart, registerables } from 'chart.js';
import { useAuth } from '../../utils/firebase/authContext';
import { LineGraph, ListItems } from '../../components/Statistics';
import { StaffAPI } from "../../APIs/StaffAPI";
import { AdminDashboardData } from "../../models/Statistics";

// Initialize Chart.js
Chart.register(...registerables);

const AdminDashboard: React.FC = () => {
    const { user } = useAuth();

    // Use the specific AdminDashboardData type in your state
    const [dashboardData, setDashboardData] = useState<AdminDashboardData | undefined>(undefined);

    // Fetch data from StaffAPI
    const getDashboard = async () => {
        try {
            const dashboard = await StaffAPI.get_admin_dashboard();
            setDashboardData(dashboard);
        } catch (error) {
            console.error("Failed to fetch admin dashboard data", error);
        }
    };

    // Fetch data on mount
    useEffect(() => {
        getDashboard();
    }, []);

    if (!dashboardData) {
        return <Box>Loading admin dashboard...</Box>;
    }

    return (
        <Box p={[4, 6, 8]}>
            {/* Greeting for Admin */}
            <Heading as="h1" size="xl" mb={6}>
                Welcome Boss, {user?.full_name}
            </Heading>

            {/* Main Dashboard Heading */}
            <Heading as="h2" size="lg" textAlign="center" mb={2}>
                Admin Dashboard Overview
            </Heading>
            <Spacer h="25px" />

            {/* Display Total Users */}
            <Box mb={6} textAlign="center">
                <Heading as="h3" size="md">
                    Total Users
                </Heading>
                <Text fontSize="2xl" fontWeight="bold" color="teal.500">
                    {dashboardData.totalUsers}
                </Text>
            </Box>

            {/* First Row of Charts: Active Users, New Users, Total Interactions */}
            <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={6}>
                <GridItem colSpan={[1, 1, 1]}>
                    <LineGraph id="activeUsersChart" graphData={dashboardData.activeUsersLast7Days} />
                </GridItem>

                <GridItem colSpan={[1, 1, 1]}>
                    <LineGraph id="newUsersChart" graphData={dashboardData.newUsersLast7Days} />
                </GridItem>

                <GridItem colSpan={[1, 2, 1]}>
                    <LineGraph id="totalInteractionsChart" graphData={dashboardData.totalInteractionsLast7Days} />
                </GridItem>
            </Grid>

            <Box height={['5px', '55px', '105px']} />

            {/* Second Row of Lists: Most Popular Questions & User Types */}
            <Grid templateColumns={['1fr', 'repeat(2, 1fr)']} gap={6}>
                <GridItem colSpan={[1, 1]}>
                    <ListItems listData={dashboardData.mostPopularQuestions} />
                </GridItem>
                <GridItem colSpan={[1, 1]}>
                    <ListItems listData={dashboardData.userTypes} />
                </GridItem>
            </Grid>
        </Box>
    );
};

export default AdminDashboard;