import * as React from "react";
import {
    Box,
    Button,
    Text,
    useColorModeValue,
    Badge,
    Flex,
    Stack, CircularProgress, CircularProgressLabel,
} from "@chakra-ui/react";
import { CasperScenario, CasperInteraction } from "../models/Models";
import { useNavigate } from "react-router-dom";

interface CasperInteractionProps {
    interaction: CasperInteraction;
    scenario: CasperScenario;
}

const CasperInteractionComp: React.FC<CasperInteractionProps> = ({ interaction, scenario }) => {
    const navigate = useNavigate();
    const responseBg = useColorModeValue('primary.50', 'primary.800');
    const borderColor = useColorModeValue('primary.200', 'primary.700');

    const handleReset = () => {
        if (window.location.href.includes("answer-a-question")) {
            window.location.reload()
        } else {
            navigate("/answer-a-question/" + interaction.casper_scenario.toHexString())
        }
    };

    return (
        <Box>
            {interaction.interaction_details.map((detail, index) => (
                <Box
                    key={index}
                    mb={4}
                    p={4}
                    bg={responseBg}
                    borderRadius="md"
                    borderWidth="1px"
                    borderColor={borderColor}
                >
                    <Flex mb={2} align="center" justify="space-between">
                        <Text fontSize="md" fontWeight="bold">
                            {`Question ${index + 1}`}
                        </Text>
                        <Badge colorScheme="blue">{`Score: ${detail.score}/4`}</Badge>
                    </Flex>
                    <Stack spacing={2}>
                        <Box>
                            <Text fontSize="sm" fontWeight="semibold">
                                Question
                            </Text>
                            <Text fontSize="sm">{scenario.questions[index]}</Text>
                        </Box>
                        <Box>
                            <Text fontSize="sm" fontWeight="semibold">
                                Your Answer
                            </Text>
                            <Text fontSize="sm">{detail.answer}</Text>
                        </Box>
                        <Box>
                            <Text fontSize="sm" fontWeight="semibold">
                                Feedback
                            </Text>
                            <Text fontSize="sm">{detail.answer_feedback}</Text>
                        </Box>
                        <Flex justify="space-between">
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold">
                                    Top Hit
                                </Text>
                                <Badge fontSize="0.7em" colorScheme="green">
                                    {detail.top_hit_on_characteristic}
                                </Badge>
                            </Box>
                            <Box>
                                <Text fontSize="sm" fontWeight="semibold">
                                    Top Missed
                                </Text>
                                <Badge fontSize="0.7em" colorScheme="red">
                                    {detail.top_missed_on_characteristic}
                                </Badge>
                            </Box>
                        </Flex>
                    </Stack>
                </Box>
            ))}
            <Button
                size="sm"
                colorScheme="primary"
                variant="outline"
                onClick={handleReset}
                _hover={{ bg: 'primary.600', color: 'white' }}
            >
                Try Again
            </Button>
        </Box>
    );
};

export default CasperInteractionComp;