import {ObjectId} from "bson";

export enum GenderEnum {
    Male = 'Male',
    Female = 'Female',
    RatherNotSay = 'Rather not say',
}

export class User {
    _id: ObjectId;
    full_name: string;
    email: string;
    phone_number?: string;
    exam_date?: Date;
    dob?: Date;
    gender: GenderEnum;
    photo_url?: string;
    user_type: ObjectId;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {
        this._id = new ObjectId(data._id.$oid);
        this.full_name = data.full_name;
        this.email = data.email;
        this.phone_number = data.phone_number;
        this.exam_date = data.exam_date ? new Date(data.exam_date.$date) : undefined;
        this.dob = data.dob ? new Date(data.dob.$date) : undefined;
        this.gender = data.gender || GenderEnum.RatherNotSay;
        this.photo_url = data.photo_url;
        this.user_type = new ObjectId(data.user_type.$oid);
        this.created_at = new Date(data.created_at.$date);
        this.updated_at = new Date(data.updated_at.$date);
    }

    is_admin(): boolean {
        return this.user_type.equals(ObjectId.createFromHexString("000000000000000000000001"));
    }
}

export class CasperScenario {
    _id: ObjectId;
    int_id: number;
    scenario_title: string;
    scenario: string;
    questions: string[];
    category: string;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {
        this._id = new ObjectId(data._id.$oid);
        this.int_id = data.int_id;
        this.scenario_title = data.scenario_title;
        this.scenario = data.scenario;
        this.questions = data.questions;
        this.category = data.category;
        this.created_at = new Date(data.created_at.$date);
        this.updated_at = new Date(data.updated_at.$date);
    }
}

// InteractionQuestionAndResponse Interface
export interface InteractionQuestionAndResponse {
    answer: string;
    answer_feedback: string;
    score: number;
    top_hit_on_characteristic: string;
    top_missed_on_characteristic: string;
}

// CasperInteraction Class
export class CasperInteraction {
    _id: ObjectId;
    casper_scenario: ObjectId;
    user: ObjectId;
    interaction_details: InteractionQuestionAndResponse[];
    time_elapsed?: number;
    created_at: Date;
    updated_at: Date;

    constructor(data: any) {
        this._id = new ObjectId(data._id.$oid);
        this.casper_scenario = new ObjectId(data.casper_scenario.$oid);
        this.user = new ObjectId(data.user.$oid);
        this.interaction_details = data.interaction_details.map((detail: any) => ({
            answer: detail.answer,
            answer_feedback: detail.answer_feedback,
            score: detail.score,
            top_hit_on_characteristic: detail.top_hit_on_characteristic,
            top_missed_on_characteristic: detail.top_missed_on_characteristic
        }));
        this.time_elapsed = data.time_elapsed ?? undefined;
        this.created_at = new Date(data.created_at.$date);
        this.updated_at = new Date(data.updated_at.$date);
    }
}