import React, { useEffect, useState } from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription
} from '@chakra-ui/react';
import {FormsAPI} from "../../APIs/FormsAPI";
import {EmailSubscription} from "../../models/Forms";

const EmailSubscriptionsPage = () => {
    const [subscriptions, setSubscriptions] = useState<EmailSubscription[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    // Fetch email subscriptions on component mount
    useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                setLoading(true);
                const data = await FormsAPI.get_all_email_subscriptions();
                setSubscriptions(data);
                setLoading(false);
            } catch (err) {
                setError('Failed to load email subscriptions: ' + err);
                setLoading(false);
            }
        };

        fetchSubscriptions().then();
    }, []);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <Spinner size="xl" />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert status="error">
                <AlertIcon />
                <AlertTitle>Error:</AlertTitle>
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        );
    }

    return (
        <Box p={4}>
            <TableContainer>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Email</Th>
                            <Th>Status</Th>
                            <Th>Created At</Th>
                            <Th>Updated At</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {subscriptions.map((subscription) => (
                            <Tr key={subscription._id.toString()}>
                                <Td>{subscription._id.toString()}</Td>
                                <Td>{subscription.email}</Td>
                                <Td>{subscription.status}</Td>
                                <Td>{new Date(subscription.created_at).toLocaleString()}</Td>
                                <Td>{new Date(subscription.updated_at).toLocaleString()}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default EmailSubscriptionsPage;